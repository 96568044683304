export default {
    'home':'Home',
    'about_us':'About Us',
    'company_address':'Contact Us',
    'contact_us':'Contact Us',
    'market_and_solution':'Scenarios and Applications',
    'changjing':'Scenarios',
    'cn':'CN',
    'en':'EN',
    'news_center':'News',
    'developer_Community':'Developer Community',
    'hr_resource':'HR',
    'training':'Talent Development',
    'join_us':'Join Us',
    'honor':'Honors',
    'investor':'Investor',
    'investorService':'Investor Service',
    'report':'announcements',
    'stock_code':'Stock Code',
    'email':'Email',
    'kefu':'Customer Services',
    'phone':'Phone',
    'legalNotice':'Legal Notices',
    'copyright':'Copyright',
    'filing_number':'Record No.: Min ICP No. 19019072',
    'copyright_belongs':'All rights reserved锛歋igmaStar Technology Co.',
    'market':'Markets',
    'solution':'Applications',
    'pre':'Previous',
    'next':'Next',
    'rel_products':'Related Products',
    'feature':'Featured Highlights',
    'xxzx':'Selection Consulting',
     'go_to_choose_sim' :'Click for selection consultation' ,
     'address':'Address',
     'express':'Express',

}